import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { parseISO, format } from 'date-fns';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { ExportHistory, ExportHistoryAndCount } from '../../types/Common.interfaces';
import { Sort } from '../../types/Cube.interfaces';
import TableCheckboxFilter from '../tableCheckboxFilter/tableCheckboxFilter';
import LoadingError from '../loadingError/loadingError';
import EmptyData from '../emptyData/emptyData';
import { Box, Typography, Skeleton, IconButton, Stack, Button } from '@mui/material';
import { DataGridPro, GridColDef, GridRenderCellParams, GridRowModel, GridSortModel } from '@mui/x-data-grid-pro';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import databaseSvg from '../../public/images/database-icon.svg';

interface Props {
  isExportHistoryPage?: boolean;
  hideFooter?: boolean;
  hideFooterPagination?: boolean;
  pagination?: boolean;
  autoHeight?: boolean;
  skeletonRows?: number;
}

const ExportHistoryTable: NextPage<Props> = ({
  isExportHistoryPage,
  hideFooter = false,
  hideFooterPagination = false,
  pagination = false,
  autoHeight = false,
  skeletonRows = 10,
}) => {
  const router = useRouter();
  const {
    defaultRowsPerPage,
    organizationData,
    activeDataset,
    loadingOrganizationData,
    getPageSize,
  } = useGlobalState();
  const { toolbarHeight, searchBarHeight } = useUiState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [controller] = useState<AbortController>(new AbortController());
  const [exportData, setExportData] = useState<ExportHistory[]>([]);
  const [rowCount, setRowCount] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [page, setPage] = useState<number>(1);
  const [sortData, setSortData] = useState<Sort>({ column: 'exportedAt', value: 'desc' });
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [loadingExports, setLoadingExports] = useState<boolean>(false);
  const [loadingExportsError, setLoadingExportsError] = useState<boolean>(false);
  const buttonRef = useRef<any>();
  const iconRef = useRef<any>();
  const columns: GridColDef[] = [
    {
      field: 'exportName',
      headerName: 'File Exported',
      flex: 1
    },
    {
      field: 'author',
      headerName: 'Exported by',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.row.user.firstName && params.row.user.lastName ?
            `${params.row.user.firstName} ${params.row.user.lastName}` : `${params.row.email || ''}`}
        </>
      ),
    },
    {
      field: 'exportSize',
      headerName: 'Export size',
      width: 190,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          {params.value}
        </>
      ),
    },
    {
      field: 'exportedAt',
      headerName: 'Date',
      width: 140,
      disableColumnMenu: true,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params: GridRenderCellParams) => {
        if (params.value) {
          return (
            <>
              {format(parseISO(params.value), 'MM/dd/yyyy')}
            </>
          );
        } else {
          return <></>;
        }
      }
    }
  ];

  useEffect(() => {
    return () => {
      controller.abort();
    };
  }, [controller]);

  useEffect(() => {
    if (organizationData) {
      setExportData([]);
    }
  }, [organizationData]);

  useEffect(() => {
    if (organizationData && activeDataset) {
      const { query } = router;
      if (query.page && query['page-size']) {
        const pageSize = getPageSize(query['page-size'] as string, 10);
        loadExportData(pageSize, +query.page, sortData.column, sortData.value);
      } else {
        loadExportData(5, 1, sortData.column, sortData.value);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationData, activeDataset, router, selectedOptions]);

  const loadExportData = async (count: number, page: number, orderBy: string, direction: string) => {
    if (organizationData && activeDataset) {
      try {
        setExportData([]);
        setLoadingExportsError(false);
        setLoadingExports(true);
        setPage(page);
        setPageSize(count);
        const countParam = count ? count : 100;
        const pageParam = page ? page : 1;
        const filterParams = selectedOptions.length > 0 ? `&filters=${selectedOptions.join(',')}` : '';
        const queryParams = `?count=${countParam}&page=${pageParam}${filterParams}&orderBy=${orderBy}&direction=${direction}`;
        const response: AxiosResponse<ExportHistoryAndCount> = await axios.get(
          `/api/clients/${organizationData.id}/datasets/${activeDataset.id}/export-history${queryParams}`,
          { signal: controller.signal, headers: { 'Content-Type': 'application/json' } }
        );
        setExportData(response.data.exports);
        setRowCount(response.data.totalCount);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setExportData([]);
          setRowCount(0);
          setLoadingExportsError(true);
        }
      }
      finally {
        setLoadingExports(false);
      }
    }
  };

  const handleSortChange = async (model: GridSortModel) => {
    if (model.length && model[0].sort) {
      setSortData({ column: model[0].field, value: model[0].sort });
      loadExportData(pageSize, page, model[0].field, model[0].sort);
    } else {
      setSortData({ column: 'exportedAt', value: 'desc' });
      loadExportData(pageSize, page, 'exportedAt', 'desc');
    }
  };

  const handleChangePageSize = (value: number) => {
    setPageSize(value);
    router.push(`/export-history?page=1&page-size=${value}`);
  };

  const handlePageChanged = (value: number) => {
    setPage(value);
    router.push(`/export-history?page=${value}&page-size=${pageSize}`);
  };

  const handleFilterChange = (value: string[]) => {
    setSelectedOptions(value);
  };

  const handleToggleFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleCloseFilter = (event:  MouseEvent | TouchEvent | React.MouseEvent<HTMLElement>) => {
    if (event.target !== buttonRef.current && event.target !== iconRef.current) {
      setAnchorEl(null);
    }
  };

  return (
    loadingExportsError ? (
      <LoadingError databaseError classes='u-py-128 u-mb-40' />
    ) : (
      <Box
        className='u-flex u-flex-direction-column'
        sx = {
          !autoHeight ? (
            { minHeight: `calc(100vh - ${toolbarHeight}px - ${searchBarHeight}px - 63px)`, height: '630px'}
          ) : (
            { minHeight: '345px' }
          )}
      >
        <Box className='table-top-wrapper u-flex u-flex-space-between u-flex-align-center u-px-16 u-p-16'>
          <Typography className='table-top-content fs-title-medium'>Recent Exports</Typography>
          <Box>
            {isExportHistoryPage ?
              null : (
                <Button
                  className='u-text-transform-none'
                  variant='text'
                  onClick={()=> router.push(`/export-history?page=1&page-size=${defaultRowsPerPage}`)}
                >
                  See All
                </Button>
              )}
            <IconButton
              ref={buttonRef}
              onClick={(event: React.MouseEvent<HTMLElement>) => handleToggleFilter(event)}
            >
              <TuneOutlinedIcon
                ref={iconRef}
                className='table-top-content-icon'
              />
            </IconButton>
          </Box>
        </Box>
        <DataGridPro
          page={page-1}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 20, 50]}
          paginationMode='server'
          columns={columns}
          rows={exportData}
          rowCount={rowCount}
          getRowId={(row: GridRowModel) => row.id}
          loading={loadingOrganizationData || loadingExports}
          sx={{
            borderRadius: '0',
            backgroundColor: '#fff',
          }}
          headerHeight={48}
          rowHeight={48}
          autoHeight={autoHeight}
          className='table'
          disableSelectionOnClick
          disableColumnFilter
          disableColumnSelector
          hideFooter={hideFooter}
          hideFooterPagination={hideFooterPagination}
          pagination={pagination}
          sortingMode='server'
          sortingOrder={['asc', 'desc']}
          hideFooterSelectedRowCount
          onSortModelChange={(model: GridSortModel) => handleSortChange(model)}
          onPageChange={(page: number) => handlePageChanged(page+1)}
          onPageSizeChange={(newPageSize: number) => handleChangePageSize(newPageSize)}
          components={{
            NoRowsOverlay: () => <EmptyData text='Export a segment and it will appear here.' />,
            NoResultsOverlay: () => <EmptyData text='Export a segment and it will appear here.' />,
            LoadingOverlay: () => (
              <Stack height='100%' alignItems='center' justifyContent='center'>
                {Array.from(Array(skeletonRows).keys()).map((value: number) => (
                  <Skeleton
                    key={value + 1}
                    variant='rectangular'
                    animation='wave'
                    width='100%'
                    height={36}
                    className='u-my-6'
                    sx={{ bgcolor: '#F5EEFA', zIndex: '1' }}
                  />
                ))}
              </Stack>
            ),
            ErrorOverlay: () => (
              <Stack height='100%' alignItems='center' justifyContent='center'>
                <Box
                  className='u-flex u-flex-justify-center u-flex-align-center u-w-64-px u-h-64-px u-br-50'
                  sx={{ background: '#D9D9D9', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)' }}
                >
                  <Image
                    src={databaseSvg}
                    alt='Database icon'
                  />
                </Box>
                <Typography className='fs-title-large u-fw-700'>Loading Error</Typography>
                <Typography className='fs-body-regular'>Oops, sorry, we seem to be having an issue on our end.</Typography>
              </Stack>
            )
          }}
        />
        <TableCheckboxFilter
          anchorEl={anchorEl}
          handleClose={handleCloseFilter}
          handleFilterChange={handleFilterChange}
          title='Exported By'
          selectedOptions={selectedOptions}
          filterEntity='exports'
        />
      </Box>
    )
  );
};

export default ExportHistoryTable;
