import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import { Dialog, Box, Typography, Button } from '@mui/material';
import { useUiState } from '../../context/uiState';
import CloseButton from '../closeButton/closeButton';

const WelcomeModal: NextPage = () => {
  const { openWelcomeModal, setOpenWelcomeModal } = useUiState();
  const router = useRouter();

  const handleCloseModel = () => {
    setOpenWelcomeModal(false);
    router.push('/');
  };

  return (
    <Dialog
      open={openWelcomeModal}
      onClose={() => handleCloseModel()}
      aria-labelledby='settings-title'
      aria-describedby='settings-description'
      fullScreen={false}
      fullWidth
      classes={{
        paper: 'dialog-root u-br-12-px'
      }}
      maxWidth='md'
    >
      <Box className='u-py-24 u-px-48'>
        <CloseButton handleCloseModal={() => handleCloseModel()} />
        <Box className='u-flex u-flex-justify-center'>
          <iframe
            src="https://www.loom.com/embed/b1d2c55cfdfa4fa28f718fd15cd07087?sid=4bc93bda-9b12-4ef0-a16b-f4bdf8f376a4"
            allowFullScreen
            width='560'
            height='315'
          >
          </iframe>
        </Box>
        <Box className='u-py-24 u-px-40'>
          <Typography className='fs-title-large u-text-align-center u-pb-16'>Welcome to RevenueBase!</Typography>
          <Typography className='u-text-align-center'>
            Explore our quick intro video and learn how to rapidly empower your Go-to-Market team with top-tier company and contact data.
            Discover the art of prioritizing your Ideal Customer Profile (ICP) accounts using premium filters and insights.
            Unleash the full potential of your sales and marketing strategy with RevenueBase!
          </Typography>
          <Box className='u-flex u-flex-justify-center u-pt-24'>
            <Button
              onClick={() => handleCloseModel()}
              className='contained-button'
            >
              Done
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default WelcomeModal;
